<lib-logo-frame>
    <ng-container form-title>
        Inserisci OTP
    </ng-container>

    <form [formGroup]="formData" autocomplete="off" novalidate class="flex flex-col">
        <mat-form-field>
            <mat-label>Enter {{ useRecoveryCode() ? 'recovery code' : 'code' }}</mat-label>
            <input matInput type="text" formControlName="code" required>
            <mat-icon matSuffix>{{ useRecoveryCode() ? 'vpn_key' : 'qr_code_2' }}</mat-icon>
            @if(formData.controls.code.errors?.['required']) {
                <mat-error>Inserire {{ useRecoveryCode() ? 'recovery code' : 'codide QR' }}</mat-error>
            }
        </mat-form-field>
        <mat-checkbox formControlName="useRecoveryCode">Usa Recovery Code</mat-checkbox>
    </form>

    <lib-loading-big-button 
        class="block mt-5"
        [loading]="loading()" 
        [disabled]="formData.invalid || formData.pristine || loading()" 
        (onClick)="login()">
        VERIFICA
    </lib-loading-big-button>
        
    @if (error()) {
        <lib-error-alert>{{ error() }}</lib-error-alert>
    }

</lib-logo-frame>


