import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LogoFrameComponent } from '../logo-frame/logo-frame.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { concatMap } from 'rxjs';
import {
	ErrorAlertComponent,
    LoadingBigButtonComponent,
} from '@webapp-nx-repo/lib-shared-common';
import { AUTH_ROUTER_TOKENS } from '../routes/router-tokens';
import { LibAuthService } from '../../service/lib-auth.service';
import { IUser } from '../../model/user';

@Component({
	selector: 'lib-auth-login-2fa',
	imports: [
		CommonModule,
		ErrorAlertComponent,
        LoadingBigButtonComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatCheckboxModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		LogoFrameComponent,
	],
	templateUrl: './login2fa.component.html',
	styles: [``],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Login2faComponent {
	private readonly destroyRef = inject(DestroyRef);
	readonly ROUTER_TOKENS = AUTH_ROUTER_TOKENS;
	private readonly router = inject(Router);
	private readonly activateRoute = inject(ActivatedRoute);
	private readonly authService = inject(LibAuthService);
	private readonly fb = inject(FormBuilder);

	loading = signal<boolean>(false);
	error = signal<string | null>(null);

	formData: FormGroup<{
		code: FormControl<string | null>;
		useRecoveryCode: FormControl<boolean>;
	}> = this.fb.group({
		code: this.fb.control<string | null>(null, [Validators.required]),
		useRecoveryCode: this.fb.nonNullable.control<boolean>(false),
	});

	useRecoveryCode = toSignal(
		this.formData.controls.useRecoveryCode.valueChanges,
		{
			initialValue: false,
		}
	);

	login() {
		this.loading.set(true);
		this.error.set(null);

		const code = this.formData.controls.code.value!;
		const useVerificationCode =
			!!this.formData.controls.useRecoveryCode.value;

		this.authService
			.twoFactorChallenge(code, useVerificationCode)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				concatMap(() => this.authService.getLoggedUser()) // save LoggedUser in authService
			)
			.subscribe({
				next: (user_data: IUser) => {
					this.loading.set(false);
					this.error.set(null);

					const returnUrl = this.authService.redirectUrl || '/';

					this.router.navigateByUrl(returnUrl);
				},
				error: () => {
					this.loading.set(false);
					this.error.set('Codice OTP non valido');
				},
			});
	}
}
